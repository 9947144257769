import { defineComponent, ref, Ref, getCurrentInstance } from '@vue/composition-api';

import { Btn, Empty } from '@/components/UI';

import FetchData from '@/components/FetchData';
import Card from '@/components/Card';
import store from '@/store';
import { generateOnlyid } from '@/public/methods';
import { VueConstructor } from 'vue/types/umd';

import { SvgPlay, SvgPause } from '@/components/MusicPlayer/svg';
import SvgShare from '@/svg/arrow';
import SvgComment from '@/svg/commit';
import SvgLove from '@/svg/thumb-up';
import SvgDownload from '@/svg/download';

import CommentList from '@/components/Article/CommentList';
import CommentPublish from '@/components/Article/CommentPublish';
import { req } from '@/api';

interface SingerAuthorInfo {
	author: string; //作者名
	authorOnlyid?: string | number; //绑定的作者惟一标识符
}
interface OtherAuthorInfo extends SingerAuthorInfo {
	territory: string; //作词lyricist作曲composer编曲arranger混音mixer歌手singer等
}
interface ImgSource {
	territory: string; //专辑图album，歌曲图song，歌手图singer，歌曲海报bill等
	imgUrl: string;
}
interface LyricSource {
	isKaraoke?: boolean; //是否以卡啦OK方式显示
	format: string; //格式，可以是lrc，可以是二进制等
	dirft?: number; //歌词时间的偏移量，精确到毫秒
	data?: any[];
	link?: string; //如果是外部资源，则
}
interface SongSource {
	type: string; //本地资源local，外部网址
	remark?: string; //备注，比如这个资源是伴奏karaoke，这个资源是128kbps的等等
	url?: string; //歌曲的真实URL，一般不这样做
	source?: string | number; //歌曲的资源ID
}
interface SongData {
	onlyid: string | number;
	title: string;
	author: string | SingerAuthorInfo[]; //如果有多个歌手
	authorOnlyid?: string | number; //歌曲信息
	allAuthor?: OtherAuthorInfo[];
	album?: string;
	albumOnlyid?: string | number;
	track?: number; //专辑里的哪一首曲目
	img?: string; //图片资源URL
	imgSource?: ImgSource[]; //其他图片资源
	lrc?: string | LyricSource; //歌词资源，可以是绑定的LRC标识号，可以是URL
	neteasyID?: number | string; //网易云音乐的绑定ID
	duration: number;
	source: string | SongSource[]; //资源
	bindOtherSource?: SongSource[];
	tag?: string[] | string; //歌曲标签，以便搜索引擎发现{原先流派等标识废弃}
	category: number; //0收藏，1原创，2参与创作，3网友作品，4翻唱填词，9伴奏
	content?: string;
	deterplay?: number; //控制资源的播放延迟等问题
	playCount: number; //音乐的播放次数
	praiseCount: number; //音乐的点赞次数
	commentCount: number; //音乐的评论数量
}
interface ControlBtn {
	key: string;
	icon: VueConstructor | string;
	text?: string;
	btnType?: string | undefined;
	operate: () => void;
}

const categoryEnum = ['favorite', 'original', 'part', 'friends', 'cover', '', '', '', '', 'karaoke'];

export default defineComponent({
	name: 'musicDetailPage',
	components: { Card, Btn, FetchData, Empty, CommentList, CommentPublish },
	setup() {
		const { proxy } = getCurrentInstance()!;
		const t = proxy.$t.bind(proxy);
		const { $modal, $message, $previewRefresh, $preview, $route } = proxy as any;
		const songData: Ref<SongData> = ref({
			onlyid: 'aaaaaaaaa',
			title: '未知歌曲未知歌曲',
			author: '未知歌手',
			authorOnlyid: 111,
			allAuthor: [{ territory: 'lyricist', author: '未知作曲家', authorOnlyid: 222 }],
			album: '未知专辑',
			albumOnlyid: 333,
			track: -1,
			img: store.state.limitImgUrl,
			imgSource: [{ territory: 'bill', imgUrl: store.state.limitImgUrl + '?海报图' }],
			lrc: {
				format: 'lrc',
				dirft: -30,
				data: [],
			},
			neteasyID: 9999,
			duration: 258,
			source: [{ type: 'local', url: '/static/1.mp3' }],
			bindOtherSource: undefined,
			tag: '不知道 不想知道',
			category: 0,
			content: '这是歌曲的简介内容这是这是歌曲的简介内容这是',
			playCount: 15,
			praiseCount: 3,
			commentCount: 2,
		});
		const touchData = (data: SongData) => {
			songData.value = data;
			$previewRefresh(); //图片预览工具刷新一下
			store.commit('changeMetaInfo', {
				title: `${data.title} - ${store.state.metaInfo.title}`,
			});
		};

		//控制按钮部分
		const controlBtns: Ref<ControlBtn[]> = ref([
			{
				key: 'play',
				icon: SvgPlay,
				text: '播放',
				btnType: 'primary',
				operate: () =>
					store.commit(
						'musicPlayerControl',
						`${generateOnlyid()}#addList#${JSON.stringify({
							addLocal: -2 /*-1最后 -2下一首*/,
							immediatePlay: true,
							data: songData.value,
						})}`
					),
			},
			{
				key: 'love',
				icon: SvgLove,
				text: '点赞',
				operate: () =>
					req('SEND_LIKE', { onlyid: $route.params.onlyid, tribe: 'music' })
						.then(() => {
							$message.success('点赞成功');
							songData.value.praiseCount++;
						})
						.catch(() => $message.warning('点赞失败')),
			},
			{
				key: 'comment',
				icon: SvgComment,
				text: '评论',
				operate: () => $message.error('不支持评论'),
			},
			{
				key: 'share',
				icon: SvgShare,
				text: '分享',
				operate: () => $modal(() => '无法分享'),
			},
			{
				key: 'download',
				icon: SvgDownload,
				text: '下载',
				operate: () => $modal('无法下载'),
			},
		]);

		return { songData, touchData, categoryEnum, t, controlBtns };
	},
});
